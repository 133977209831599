<template>
  <b-col class="col-xs-12 col-sm-12 col-md-4 col-xl-4">
    <slot />
  </b-col>
</template>

<script>
import { BCol } from 'bootstrap-vue'

export default {
  name: 'LenonResponsive',
  components: { BCol },
}
</script>

<style scoped>

</style>
