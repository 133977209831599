<template>
  <component :is="'b-card'">
    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <!-- Media -->
        <b-media class="mt-5">
          <template #aside>
            <div class="text-center mb-2">
              <lenon-profile-photo
                v-show="!updatingPhoto"
                size="90"
                variant="light-primary"
                :photo="photo"
              />
              <b-spinner
                v-show="updatingPhoto"
                style="width: 90px; height: 90px;"
                variant="primary"
              />
            </div>
          </template>
          <div style="margin: 10px 0px;">
            <b>{{ authUser.first_name }} {{ authUser.last_name }}</b>
          </div>
          <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex flex-wrap"
          >
            <lenon-file-button
              label="Change"
              @onFileUpload="updateLogo"
            />
            <lenon-button
              variant="outline-danger"
              class="ml-1"
              icon="XIcon"
              label="Remove"
              @onClick="removeUserPhoto()"
            />
          </div>
          <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex flex-wrap"
          >
            <lenon-file-button
              label="Change"
              @onFileUpload="updateLogo"
            />
            <lenon-button
              variant="outline-danger"
              class="mt-1"
              icon="XIcon"
              label="Remove"
              @onClick="removeUserPhoto()"
            />
          </div>
        </b-media>
        <validation-observer
          ref="bankForm"
          v-slot="{invalid}"
        >
          <div class="row mt-2">
            <div class="col-md-4">
              <lenon-input
                v-model="profile.first_name"
                name="first_name"
                label="First Name"
                rules="required|max_length:50"
              />
            </div>
            <div class="col-md-4">
              <lenon-input
                v-model="profile.last_name"
                name="last_name"
                label="Last Name"
                rules="required|max_length:50"
              />
            </div>
            <div class="col-md-4">
              <lenon-input
                v-model="profile.phone"
                name="phone"
                label="Phone"
                rules="required|max_length:15"
              />
            </div>
            <div class="col-md-4">
              <lenon-input
                v-model="profile.email"
                name="email"
                label="Email"
                rules="required|email"
              />
            </div>
          </div>
          <lenon-button
            label="Save"
            class="float-right"
            :disabled="invalid"
            :loading="userFormLoading"
            @onClick="saveUser()"
          />
        </validation-observer>
      </b-tab>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Security</span>
        </template>
        <validation-observer
          ref="bankForm"
          v-slot="{invalid}"
        >
          <div class="row mt-2">
            <div class="col-md-4">
              <lenon-input
                v-model="security.current_password"
                name="current_password"
                label="Current Password"
                placeholder="Current Password"
                type="password"
                rules="required"
              />
            </div>
            <div class="col-md-4">
              <lenon-input
                v-model="security.password"
                name="password"
                label="Password"
                placeholder="Password"
                type="password"
                rules="required|min_length:6"
              />
            </div>
            <div class="col-md-4">
              <lenon-input
                v-model="security.password_confirmation"
                name="password_confirmation"
                label="Confirm Password"
                placeholder="Password Confirmation"
                type="password"
                rules="required"
              />
            </div>
          </div>
          <lenon-button
            label="Save"
            class="float-right"
            :disabled="invalid"
            :loading="updatingPassword"
            @onClick="updateUserPassword()"
          />
        </validation-observer>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BMedia, BSpinner,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonFileButton from '@/lenon/components/LenonFileButton.vue'
import LenonResponsive from '@/lenon/components/LenonResponsive.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import { ValidationObserver } from 'vee-validate'
import {
  REMOVE_USER_PHOTO_M,
  UPDATE_USER_INFO_M,
  UPDATE_USER_PASSWORD_M,
  UPLOAD_USER_PHOTO_M,
} from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto.vue'

export default {
  components: {
    LenonProfilePhoto,
    LenonInput,
    LenonResponsive,
    LenonFileButton,
    LenonButton,
    BTab,
    BTabs,
    BCard,
    BMedia,
    ValidationObserver,
    BSpinner,
  },
  mixins: [showToast],
  data() {
    return {
      profile: {
        id: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
      },
      security: {
        current_password: null,
        password: null,
        password_confirmation: null,
      },
      userFormLoading: false,
      updatingPhoto: false,
      updatingPassword: false,
    }
  },
  computed: {
    photo() {
      return this.$store.getters['auth/userProfile']
    },
    authUser() {
      return this.$store.getters['auth/user']
    },
  },
  watch: {
    authUser() {
      this.setUser()
    },
  },
  mounted() {
    this.setUser()
  },
  methods: {
    setUser() {
      this.profile.id = this.authUser.id
      this.profile.first_name = this.authUser.first_name
      this.profile.last_name = this.authUser.last_name
      this.profile.email = this.authUser.email
      this.profile.phone = this.authUser.phone
    },
    saveUser() {
      this.userFormLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_USER_INFO_M,
        variables: { input: this.profile },
      }).then(res => {
        this.userFormLoading = false
        this.$store.commit('auth/updateUser', res.data.updateUserInfo)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.userFormLoading = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
    updateUserPassword() {
      this.updatingPassword = true
      this.$apollo.mutate({
        mutation: UPDATE_USER_PASSWORD_M,
        variables: { input: this.security },
      }).then(res => {
        this.updatingPassword = false
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.updatingPassword = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
    removeUserPhoto() {
      this.updatingPhoto = true
      this.$apollo.mutate({
        mutation: REMOVE_USER_PHOTO_M,
        variables: { input: this.security },
      }).then(res => {
        this.updatingPhoto = false
        this.$store.commit('auth/removePhoto')
        this.showSuccess('Removed Successfully')
      }).catch(err => {
        this.updatingPhoto = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
    updateLogo(e) {
      this.updatingPhoto = true
      this.$apollo.mutate({
        mutation: UPLOAD_USER_PHOTO_M,
        variables: { input: { photo: e.file } },
      }).then(res => {
        this.updatingPhoto = false
        this.$store.commit('auth/updateUserPhoto', res.data.updateUserPhoto)
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.updatingPhoto = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
  },
}
</script>

<style>

</style>
